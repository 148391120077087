import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { ConfigEnum } from "@/enums/httpEnum";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Login.vue"),
    meta: {
      title: "登录",
      requiresAuth: false,
    },
  },
  {
    path: "/home",
    name: "home",
    component: () => import(/* webpackChunkName: "home" */ "../views/Home.vue"),
    meta: {
      title: "首页",
    },
  },
  {
    path: "/layout",
    name: "layout",
    component: () =>
      import(/* webpackChunkName: "layout" */ "../components/CustomLayout.vue"),
    children: [
      {
        path: "/account",
        name: "account",
        component: () =>
          import(/* webpackChunkName: "account" */ "../views/Account.vue"),
        meta: {
          title: "账户信息",
        },
      },
      {
        path: "/sortingWeighing",
        name: "sortingWeighing",
        component: () =>
          import(
            /* webpackChunkName: "sortingWeighing" */ "../views/SortingWeighing.vue"
          ),
        meta: {
          title: "分拣称重",
        },
      },
      {
        path: "/sortingWeighingFood",
        name: "sortingWeighingFood",
        component: () =>
          import(
            /* webpackChunkName: "sortingWeighing" */ "../views/SortingWeighingFood.vue"
          ),
        meta: {
          title: "食材分拣称重",
        },
      },
      {
        path: "/sortingWeighingSupplier",
        name: "sortingWeighingSupplier",
        component: () =>
          import(
            /* webpackChunkName: "SortingWeighingSupplier" */ "../views/SortingWeighingSupplier.vue"
          ),
        meta: {
          title: "分拣称重",
        },
      },
      {
        path: "/smartOutbound",
        name: "smartOutbound",
        component: () =>
          import(
            /* webpackChunkName: "SmartOutbound" */ "../views/SmartOutbound.vue"
          ),
        meta: {
          title: "智慧出库",
        },
      },
      {
        path: "/outbound",
        name: "outbound",
        component: () =>
          import(/* webpackChunkName: "Outbound" */ "../views/Outbound.vue"),
        meta: {
          title: "分拣出库",
        },
      },
      {
        path: "/replenishment",
        name: "replenishment",
        component: () =>
          import(
            /* webpackChunkName: "replenishment" */ "../views/Replenishment.vue"
          ),
        meta: {
          title: "补货",
        },
      },
    ],
  },
  {
    path: "/404",
    name: "notfount",
    component: () => import(/* webpackChunkName: "404" */ "../views/404.vue"),
  },
  {
    // path: "*", // 这样用，vue3已经不支持，得下面的方式
    path: "/:pathMath(.*)", // 此处需特别注意置于最底部
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  let token = localStorage.getItem(ConfigEnum.TOKEN);
  if (to.name === "login" && token) {
    next("/home");
  } else if (to.name === "login" && !token) {
    next();
  } else if (!token) {
    next({ name: "login" });
  } else {
    next();
  }
});
export default router;
